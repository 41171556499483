// extracted by mini-css-extract-plugin
export var button = "style-module--button--9298f";
export var cardImage = "style-module--card-image--4c8c8";
export var cardImageImg = "style-module--card-image-img--3f289";
export var cardRow = "style-module--card-row--42681";
export var cardValuesHeader = "style-module--card-values-header--ae726";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--37b3f";
export var description = "style-module--description--73f7c";
export var dropdown = "style-module--dropdown--706bc";
export var heading = "style-module--heading--e3ed0";
export var innerProductComponent = "style-module--inner-product-component--522b8";
export var listHeader = "style-module--list-header--96148";
export var listHeaderMobile = "style-module--list-header-mobile--d6367";
export var productComponent = "style-module--product-component--76866";
export var rightContainer = "style-module--right-container--7851d";
import Feature from "../Feature"
import CreditCardOffer from "../offer-models/CreditCardOffer"
import CreditCardProduct from "../product-models/CreditCardProduct"
import Product from "../Product"

let obj: any = null
export default class CreditCardFeature extends Feature {

  // = ABSTRACT OVERRIDES =
  public getProductList(makeProductComponent: (product: Product, key: number, isLast?: boolean | undefined, offerFilter?: string, id?: string) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []

    const sortedProducts: Product[] = []
    Object.values(this.products).forEach(p => {
      sortedProducts.push(p)
    })
    sortedProducts.sort((a, b) => this.compare(a, b))

    // Categorise by providers
    const productsByProviders: { [id: string]: Product[] } = {}
    sortedProducts.forEach(product => {
      const productProvider: string = product.providerName

      if (productProvider in productsByProviders) {
        productsByProviders[productProvider].push(product)
      } else {
        productsByProviders[productProvider] = [product]
      }
    })

    // Fill product list
    Object.keys(productsByProviders).forEach(providerName => {
      const products: Product[] = productsByProviders[providerName]

      // Add Provider Header to list
      if (products.length > 0) {
        productList.push(makeCategoryHeader(providerName))
      }

      // Add Product Components to list
      products.forEach((product: any, key: number) => {
        productList.push(makeProductComponent(product, key))
      })
    })

    return productList
  }

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    // filterAmounts
    const filterAmounts: { [id: string]: number } = {
      "No / Low Interest": 0,
      "No / Low Annual Fee": 0,
      "No Foreign Fees": 0,
      Rewards: 0,
      "Qantas Frequent Flyer": 0,
      "Velocity Frequent Flyer": 0,
      "Travel Insurance": 0,
      "Airport Lounge Passes": 0,
      "0% Balance Transfer": 0,
      "0% Purchase Rate": 0,
      "Bonus Points": 0,
      "Cash Back": 0,
      "Major Banks": 0,
      Challengers: 0,
    }

    Object.keys(this.products).forEach((productId: string) => {
      const product: Product = this.products[productId]
      if (!(product instanceof CreditCardProduct)) {
        return
      }
      // filterAmounts
      if (product.purchaseRate < 0.14) {
        filterAmounts["No / Low Interest"] += 1
      }
      if (product.totalAfPrimary < 40) {
        filterAmounts["No / Low Annual Fee"] += 1
      }
      if (product.fxFee === 0) {
        filterAmounts["No Foreign Fees"] += 1
      }
      if (product.rewardProgramId !== 0) {
        filterAmounts["Rewards"] += 1
      }
      if (product.rewardProgramId === 100) {
        filterAmounts["Qantas Frequent Flyer"] += 1
      }
      if (product.rewardProgramId === 200) {
        filterAmounts["Velocity Frequent Flyer"] += 1
      }
      if (product.osTravelInsurance === "Y") {
        filterAmounts["Travel Insurance"] += 1
      }
      if (product.loungePassDescription !== null) {
        filterAmounts["Airport Lounge Passes"] += 1
      }
      for (var offerId in product.offers) {
        const offer: CreditCardOffer = CreditCardOffer.Initialise(product.offers[offerId])
        if (offer.hasZeroBalanceTransfer()) {
          filterAmounts["0% Balance Transfer"] += 1
        }
        if (offer.hasZeroPurchaseRate()) {
          filterAmounts["0% Purchase Rate"] += 1
        }
        if (offer.bonusPointsY1 || offer.bonusPointsY2 || offer.bonusPointsY3) {
          filterAmounts["Bonus Points"] += 1
        }
        if (offer.cashBack !== null) {
          filterAmounts["Cash Back"] += 1
        }
      }
      if (product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400") {
        filterAmounts["Major Banks"] += 1
      } else {
        filterAmounts["Challengers"] += 1
      }
    })

    return filterAmounts
  }

  public getTitle(): string {
    let title = ""

    if (this.name === "Low Rate") {
      let minRate = Number.MAX_VALUE
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        if (product.purchaseRate < minRate) {
          minRate = product.purchaseRate
        }
      })
      
      title = "Compare Lowest Interest Rate Credit Cards, from " + (minRate*100).toLocaleString() + "% p.a."
    } else if (this.name === "Interest Free") {
      title = "Compare Interest Free Credit Cards. No Interest Ever"
    } else if (this.name === "Balance Transfer") {
      let maxMonths = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.btMonths && offer.btMonths > maxMonths) {
            maxMonths = offer.btMonths
          }
        })
      })
      
      title = "Best Balance Transfer Credit Cards. 0% for up to " + maxMonths + " Months"
    } else if (this.name === "0% Purchase") {
      let maxMonths = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.introPurchaseMonths && offer.introPurchaseMonths > maxMonths) {
            maxMonths = offer.introPurchaseMonths
          }
        })
      })
      
      title = "Best 0% Purchase Credit Cards. Interest Free for " + maxMonths + " Months"
    } else if (this.name === "No Annual Fee") {
      let maxCashBack = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.cashBack && offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        })
      })

      title = "Compare No Annual Fee Credit Card offers. $" + maxCashBack.toLocaleString() + " Cash Back"
    }
    else if (this.name === "Cash Back") {
      let maxCashBack = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.cashBack && offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        })
      })

      title = "Best Cashback Credit Cards. Get $" + maxCashBack.toLocaleString() + " Back | Stay or Go"
    } else if (this.name === "No Foreign Transaction Fee") {
      title = "Compare No International Fee Credit Cards | Stay or Go"
    } else if (this.name === "Travel Insurance") {
      title = "Best Credit Card Travel Insurance Options | Stay or Go"
    }
    else {
      title = this.name + " Credit Card Comparison & Review | Stay or Go "
    }

    return title
  }

  public getAutoDescription(): string {
    let description = ""

    if (this.name === "Low Rate") {
      let minRate = Number.MAX_VALUE
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        if (product.purchaseRate < minRate) {
          minRate = product.purchaseRate
        }
      })

      description = "Review the best Low Interest Rate Credit Card offers and apply. Compare your existing card. Low rates from " + (minRate * 100).toLocaleString() + "% p.a. Get a better deal with Stay or Go."
    } else if (this.name === "Interest Free") {
      description = "Compare and apply for a leading Interest Free Credit Card. No interest ever. No international transaction fees. Get a better deal with Stay or Go."
    } else if (this.name === "Balance Transfer") {
      let maxMonths = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.btMonths && offer.btMonths > maxMonths) {
            maxMonths = offer.btMonths
          }
        })
      })

      description = "Compare the latest credit card Balance Transfer offers including 0% for " + maxMonths + " months. Review your potential savings. See what your bank will offer you to stay."
    } else if (this.name === "0% Purchase") {
      let maxMonths = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.introPurchaseMonths && offer.introPurchaseMonths > maxMonths) {
            maxMonths = offer.introPurchaseMonths
          }
        })
      })

      description = "Compare the latest Interest Free Credit Card offers, including 0% on purchases for " + maxMonths + " months. Review your potential savings. See what your bank will offer you to stay."
    } else if (this.name === "No Annual Fee") {
      let maxMonths = 0
      let maxCashBack = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.introPurchaseMonths && offer.introPurchaseMonths > maxMonths) {
            maxMonths = offer.introPurchaseMonths
          }
          if (offer.cashBack && offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        })
      })
      
      description = "Save with a no annual fee credit card. Compare offers including $" + maxCashBack.toLocaleString() + " cash back and 0% on Purchases for " + maxMonths + " months. See what you could save with Stay or Go."
    }
    else if (this.name === "Cash Back") {
      let maxCashBack = 0
      Object.values(this.products).forEach(p => {
        const product = p as CreditCardProduct
        Object.values(product.offers).forEach(o => {
          const offer = o as CreditCardOffer
          if (offer.cashBack && offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        })
      })

      description = "Compare the latest Cashback and Cash Rewards Credit Card offers including $" + maxCashBack.toLocaleString() + " cash back. Get a better deal today."
    } else if (this.name === "No Foreign Transaction Fee") {
      description = "Save on foreign transaction fees when travelling and shopping online with a No International Fee Credit Card. Compare top cards including 28 Degree Mastercard."
    } else if (this.name === "Travel Insurance") {
      description = "Complementary credit card travel insurance can save you a lot of money. Plus many cards offer other travel benefits. Compare your options with Stay or Go."
    }
    else {
      description = "Compare the latest " + this.name + " Credit Card offers. Review your existing credit card. Get a better deal today."
    }

    return description
  }

  // = AUTOMATED FUNCTIONS =
  public static Parse(d: string): Feature {
    return CreditCardFeature.Create(JSON.parse(d))
  }
  public static Create(d: any, field: string = "root"): Feature {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.name, false, field + ".name")
    checkString(d.urlSlug, true, field + ".urlSlug")
    checkString(d.defaultSort, false, field + ".defaultSort")
    d.nameFormatted = d.name
    if (d.name === "0% Balance Transfer") {
      d.heading = "Balance Transfer"
    } else {
      d.heading = d.name
    }
    d.products = {}
    return new CreditCardFeature(d)
  }
  public static Initialise(d: any): Feature {
    return new CreditCardFeature(d)
  }
  private constructor(d: any) {
    super(d)
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}

import { CircularProgress, useMediaQuery } from "@mui/material"
// import CardValuesHeader from "components/content/card-values-header"
import { CardValuesHeader } from "components/content/product-values-header"
import FilterMenu from "components/content/filter-menu"
import Sort from "components/content/sort"
import Markdown from "components/markdown"
import { compileSchemaOrgQa } from "components/schema"
import { cardImage } from "components/survey/utils"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Feature from "models/Feature"
import Offer from "models/Offer"
import Product from "models/Product"
import Provider from "models/Provider"
import Reward from "models/Reward"
import React, { useEffect, useState } from "react"
import { makeSchemaOrgProvider } from "components/schema"
import { CreditCardDisclaimer } from "../../components/content/content-disclaimer"
import ProductComponent from "../../components/content/product-component"
import Layout from "../../components/layout"
import { ListHeaderProvider } from "components/content/list-header"
import * as styles from "./style.module.scss"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import CreditCardOffer from "models/offer-models/CreditCardOffer"
import CreditCardProvider from "models/provider-models/CreditCardProvider"
import CreditCardReward from "models/reward-models/CreditCardReward"
import CreditCardFeature from "models/feature-models/CreditCardFeature"
import ReturnLinkChain from "components/content/return-link-chain"
import { CardAds as Ads } from "components/content/ads"
import ProviderArticle from "components/content/provider-articles"
import Pagination from "components/pagination"
import Faq from "components/content/faq"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderTemplate = (props: any) => {
  const { categoryJson, dateUpdated, ads, qa }: { categoryJson: Provider | Reward | Feature; dateUpdated: string; ads: any; qa?: any } = JSON.parse(props.pageContext.p)

  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const [filter, setFilter] = useState<string[]>([])
  const [sort, setOnlySort] = useState(categoryJson.defaultSort ? categoryJson.defaultSort : "Product Category")
  const [tempFilter, setTempFilter] = useState<string[]>([])
  const [linkFirst, setLinkFirst] = useState(true)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const [page, setPage] = useState(0)
  const [offersPerPage, setOffersPerPage] = useState(10)

  const [isLoading, setLoading] = useState(false)

  const updateFilter = (v: string[]) => {
    setPage(0)
    setFilter(v)
  }

  const updateOnlySort = (v: string) => {
    setPage(0)
    setOnlySort(v)
  }

  const updateTempFilter = (v: string[]) => {
    setPage(0)
    setTempFilter(v)
  }

  function makeProductComponent(product: Product, key: number, isLast?: boolean, offerFilter?: string) {
    return (
      <div key={product.id.toString() + "-" + key.toString()} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }} className={styles.productComponent}>
        <div className={styles.innerProductComponent}>
          <ProductComponent product={product as CreditCardProduct} learnMore offerFilter={offerFilter} isTablet={isTablet} />
        </div>
      </div>
    )
  }

  function isProductComponent(component: JSX.Element) {
    return component.props.className && component.props.className === styles.productComponent
  }

  function makeCategoryHeader(headerName: string) {
    return (
      // <div key={headerName} className={!isWide || isTablet ? styles.listHeaderMobile : styles.listHeader}>
      //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{headerName}</div>
      // </div>
      <ListHeaderProvider key={headerName} isMobile={!isWide || isTablet}>
        {headerName}
      </ListHeaderProvider>
    )
  }

  function setSort(name: string) {
    setLoading(true)
    updateOnlySort(name)
  }

  let category: any = null
  // Initialise Provider, Reward or Feature (TODO: identifying class might need to be more robust)
  if ("big4" in categoryJson) {
    category = CreditCardProvider.Initialise(categoryJson)
  } else if ("pointsName" in categoryJson) {
    category = CreditCardReward.Initialise(categoryJson)
  } else {
    category = CreditCardFeature.Initialise(categoryJson)
  }

  // sort and filter
  let productList: JSX.Element[] = category.getProductList(makeProductComponent, makeCategoryHeader)

  // Filter out products
  const productGroups: JSX.Element[][] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        // less hacky
        // if (typeof productComponent.props.children.props.children !== "string") {
        const propsProduct: CreditCardProduct = productComponent.props.children.props.children.props.product
        let pricingFilter: boolean[] = []
        let rewardsFilter: boolean[] = []
        let offerFilter: boolean[] = []
        let featureFilter: boolean[] = []
        let providerFilter: boolean[] = []
        let availabilityFilter: boolean[] = []
        filter.forEach(filterType => {
          switch (filterType) {
            case "No / Low Interest":
              if (propsProduct.purchaseRate >= 0.14) {
                pricingFilter.push(false)
              } else {
                pricingFilter.push(true)
              }
              break
            case "No / Low Annual Fee":
              if (propsProduct.totalAfPrimary >= 40) {
                pricingFilter.push(false)
              } else {
                pricingFilter.push(true)
              }
              break
            case "No Foreign Fees":
              if (propsProduct.fxFee !== 0) {
                pricingFilter.push(false)
              } else {
                pricingFilter.push(true)
              }
              break
            case "Rewards":
              if (propsProduct.rewardProgramId === 0) {
                rewardsFilter.push(false)
              } else {
                rewardsFilter.push(true)
              }
              break
            case "Qantas Frequent Flyer":
              if (propsProduct.rewardProgramId !== 100) {
                rewardsFilter.push(false)
              } else {
                rewardsFilter.push(true)
              }
              break
            case "Velocity Frequent Flyer":
              if (propsProduct.rewardProgramId !== 200) {
                rewardsFilter.push(false)
              } else {
                rewardsFilter.push(true)
              }
              break
            case "0% Balance Transfer":
              if (Object.keys(propsProduct.offers).length === 0) {
                offerFilter.push(false)
              } else {
                for (var offerId in propsProduct.offers) {
                  if (!CreditCardOffer.Initialise(propsProduct.offers[offerId]).hasZeroBalanceTransfer()) {
                    offerFilter.push(false)
                  } else {
                    offerFilter.push(true)
                  }
                }
              }
              break
            case "0% Purchase Rate":
              if (Object.keys(propsProduct.offers).length === 0) {
                offerFilter.push(false)
              } else {
                for (var offerId in propsProduct.offers) {
                  if (!CreditCardOffer.Initialise(propsProduct.offers[offerId]).hasZeroPurchaseRate()) {
                    offerFilter.push(false)
                  } else {
                    offerFilter.push(true)
                  }
                }
              }
              break
            case "Bonus Points":
              if (Object.keys(propsProduct.offers).length === 0) {
                offerFilter.push(false)
              } else {
                for (var offerId in propsProduct.offers) {
                  const offer = propsProduct.offers[offerId] as CreditCardOffer
                  if (!(offer.bonusPointsY1 || offer.bonusPointsY2 || offer.bonusPointsY3)) {
                    offerFilter.push(false)
                  } else {
                    offerFilter.push(true)
                  }
                }
              }
              break
            case "Cash Back":
              if (Object.keys(propsProduct.offers).length === 0) {
                offerFilter.push(false)
              } else {
                for (var offerId in propsProduct.offers) {
                  const offer = propsProduct.offers[offerId] as CreditCardOffer
                  if (offer.cashBack === null) {
                    offerFilter.push(false)
                  } else {
                    offerFilter.push(true)
                  }
                }
              }
              break
            case "Travel Insurance":
              if (propsProduct.osTravelInsurance !== "Y") {
                featureFilter.push(false)
              } else {
                featureFilter.push(true)
              }
              break
            case "Airport Lounge Passes":
              if (propsProduct.loungePassDescription === null) {
                featureFilter.push(false)
              } else {
                featureFilter.push(true)
              }
              break
            case "Major Banks":
              if (!(propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400")) {
                providerFilter.push(false)
              } else {
                providerFilter.push(true)
              }
              break
            case "Challengers":
              if (propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400") {
                providerFilter.push(false)
              } else {
                providerFilter.push(true)
              }
              break
            case "For Sale":
              if (propsProduct.forSale !== "Y" && propsProduct.forSale !== "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
            case "Not For Sale":
              if (propsProduct.forSale === "Y" || propsProduct.forSale === "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
          }
        })
        if ((pricingFilter.includes(true) || pricingFilter.length === 0) && (rewardsFilter.includes(true) || rewardsFilter.length === 0) && (offerFilter.includes(true) || offerFilter.length === 0) && (featureFilter.includes(true) || featureFilter.length === 0) && (providerFilter.includes(true) || providerFilter.length === 0) && (availabilityFilter.includes(true) || availabilityFilter.length === 0)) {
          productGroups[productGroups.length - 1].push(productComponent)
        }
      } else {
        if (sort === "Product Category") {
          productGroups.push([productComponent])
        } else {
          productGroups.push([])
        }
      }
    }
  })

  productList = []
  productGroups.forEach(group => {
    if (group.length > 1 || (sort !== "Product Category" && group.length > 0)) {
      productList = productList.concat(group)
    }
  })

  // Add "No Products" component if empty
  if (productList.length === 0) {
    productList.push(<div style={{ textAlign: "center", fontSize: "24px", fontFamily: "Arboria-Book" }}>No Products</div>)
  } else {
    // Sort products
    if (sort !== "Product Category") {
      switch (sort) {
        case "Account Fees":
          productList.sort((a, b) => a.props.children.props.children.props.product.annualFee - b.props.children.props.children.props.product.annualFee)
          break
        case "Purchase Rate":
          productList.sort((a, b) => a.props.children.props.children.props.product.purchaseRate - b.props.children.props.children.props.product.purchaseRate)
          break
        case "Points per $1 Spend":
          productList.sort((a, b) => b.props.children.props.children.props.product.pointsPerSpend - a.props.children.props.children.props.product.pointsPerSpend)
          break
        case "Bonus Points Offer":
          productList.sort((a, b) => CreditCardProduct.Initialise(b.props.children.props.children.props.product).getHighestBonusPoints() - CreditCardProduct.Initialise(a.props.children.props.children.props.product).getHighestBonusPoints())
          break
        case "Balance Transfer Offer":
          productList.sort((a, b) => CreditCardProduct.Initialise(b.props.children.props.children.props.product).getHighestBTMonths() - CreditCardProduct.Initialise(a.props.children.props.children.props.product).getHighestBTMonths())
          break
        case "Purchase Rate Offer":
          productList.sort((a, b) => CreditCardProduct.Initialise(b.props.children.props.children.props.product).getHighestIntroPurchaseMonths() - CreditCardProduct.Initialise(a.props.children.props.children.props.product).getHighestIntroPurchaseMonths())
          break
        case "Cash Back Offer":
          productList.sort((a, b) => CreditCardProduct.Initialise(b.props.children.props.children.props.product).getHighestCashBack() - CreditCardProduct.Initialise(a.props.children.props.children.props.product).getHighestCashBack())
      }
      if (linkFirst) {
        productList.sort((a, b) => {
          const productA = a.props.children.props.children.props.product
          const productB = b.props.children.props.children.props.product
          const hasOfferA = Object.values(productA.offers).some(o => o.active === "Y" && o.landingPageUrl != null)
          const hasOfferB = Object.values(productB.offers).some(o => o.active === "Y" && o.landingPageUrl != null)

          if (hasOfferA && !hasOfferB) {
            return -1
          } else if (!hasOfferA && hasOfferB) {
            return 1
          } else {
            return 0
          }
        })
      }
    }
  }

  // sortList
  const sortList: string[] = ["Product Category", "Account Fees", "Purchase Rate", "Points per $1 Spend", "Bonus Points Offer", "Balance Transfer Offer", "Purchase Rate Offer", "Cash Back Offer"]

  // filterList
  const filterList: { [type: string]: string[] } = {
    pricing: ["No / Low Interest", "No / Low Annual Fee", "No Foreign Fees"],
    rewards: ["Rewards", "Qantas Frequent Flyer", "Velocity Frequent Flyer"],
    offer: ["0% Balance Transfer", "0% Purchase Rate", "Bonus Points", "Cash Back"],
    feature: ["Travel Insurance", "Airport Lounge Passes"],
    provider: ["Major Banks", "Challengers"],
    availability: ["For Sale", "Not For Sale"],
  }

  // filterAmounts
  const filterAmounts: { [id: string]: number } = {
    "No / Low Interest": 0,
    "No / Low Annual Fee": 0,
    "No Foreign Fees": 0,
    Rewards: 0,
    "Qantas Frequent Flyer": 0,
    "Velocity Frequent Flyer": 0,
    "Travel Insurance": 0,
    "Airport Lounge Passes": 0,
    "0% Balance Transfer": 0,
    "0% Purchase Rate": 0,
    "Bonus Points": 0,
    "Cash Back": 0,
    "Major Banks": 0,
    Challengers: 0,
    "For Sale": 0,
    "Not For Sale": 0,
  }

  // filterAmounts
  let totalAmount: number = 0
  productList.forEach((productComponent: JSX.Element) => {
    if (productComponent.props.children !== "No Products") {
      // if (typeof productComponent.props.children.props.children !== "string") {
      if (isProductComponent(productComponent)) {
        // less hacky
        totalAmount += 1
        const product: CreditCardProduct = productComponent.props.children.props.children.props.product

        // filterAmounts
        if (product.purchaseRate < 0.14) {
          filterAmounts["No / Low Interest"] += 1
        }
        if (product.totalAfPrimary < 40) {
          filterAmounts["No / Low Annual Fee"] += 1
        }
        if (product.fxFee === 0) {
          filterAmounts["No Foreign Fees"] += 1
        }
        if (product.rewardProgramId !== 0) {
          filterAmounts["Rewards"] += 1
        }
        if (product.rewardProgramId === 100) {
          filterAmounts["Qantas Frequent Flyer"] += 1
        }
        if (product.rewardProgramId === 200) {
          filterAmounts["Velocity Frequent Flyer"] += 1
        }
        if (product.osTravelInsurance === "Y") {
          filterAmounts["Travel Insurance"] += 1
        }
        if (product.loungePassDescription !== null) {
          filterAmounts["Airport Lounge Passes"] += 1
        }
        for (var offerId in product.offers) {
          const offer: CreditCardOffer = CreditCardOffer.Initialise(product.offers[offerId])
          if (offer.hasZeroBalanceTransfer()) {
            filterAmounts["0% Balance Transfer"] += 1
          }
          if (offer.hasZeroPurchaseRate()) {
            filterAmounts["0% Purchase Rate"] += 1
          }
          if (offer.bonusPointsY1 || offer.bonusPointsY2 || offer.bonusPointsY3) {
            filterAmounts["Bonus Points"] += 1
          }
          if (offer.cashBack !== null) {
            filterAmounts["Cash Back"] += 1
          }
        }
        if (product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400") {
          filterAmounts["Major Banks"] += 1
        } else {
          filterAmounts["Challengers"] += 1
        }
        if (product.forSale === "Y" || product.forSale === "EXCLUDED") {
          filterAmounts["For Sale"] += 1
        } else {
          filterAmounts["Not For Sale"] += 1
        }
      }
    }
  })

  // Pagination
  const paginatedProductLists: JSX.Element[][] = []
  let paginatedProductList: JSX.Element[] = []
  const counts: number[] = []
  let count = 0

  for (let i = 0; i < productList.length; i++) {
    paginatedProductList.push(productList[i])
    if (isProductComponent(productList[i])) {
      count += 1
    }
    if (count === 10) {
      paginatedProductLists.push(paginatedProductList)
      counts.push(count)
      paginatedProductList = []
      count = 0
    }
  }
  if (paginatedProductList.length > 0) {
    paginatedProductLists.push(paginatedProductList)
  }
  counts.push(count)

  productList = paginatedProductLists[page]

  const paginationRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (productList.length > 0) {
      setLoading(false)
    }
  }, [isLoading])

  return (
    <Layout title={category.getTitle()} description={category.getAutoDescription()} urlSlug={`${category.urlSlug ? category.urlSlug : category.nameFormatted}-credit-cards`} dateUpdated={dateUpdated}>
      {"big4" in categoryJson ? makeSchemaOrgProvider(categoryJson, "cc") : null}
      {isLoading ? (
        <div style={{ position: "absolute", marginLeft: "50%", marginRight: "50%", marginTop: "30%", zIndex: 99 }}>
          <CircularProgress thickness={6} />
        </div>
      ) : null}
      <div className="page-container" style={{ display: "flex", alignItems: "stretch" }}>
        <div className="content-provider-container" style={{ maxWidth: isTablet ? (isWide ? "648px" : "395px") : "960px" }}>
          {/* <div style={{ marginBottom: "20px" }}>
            <Link
              style={{ textDecoration: "none" }}
              to="/credit-cards/"
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.BROWSER_BACK, { breadcrumbs: true })
              }}
            >
              <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
                {"Credit Cards"}
              </Typography.P>
            </Link>
          </div> */}
          <ReturnLinkChain linkChain={[{ name: "Credit Cards", to: "/credit-cards/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} />
          <div className={styles.heading}>
            <Typography.H1>Compare {category.heading} Credit Cards</Typography.H1>
          </div>
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {category.getAutoDescription()}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {ads ? <Ads ads={ads} /> : null}
          <div ref={paginationRef} />
          <div className={styles.cardValuesHeader}>
            <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} mobile={true} linkFirst={linkFirst} setLinkFirst={setLinkFirst} productValuesHeaderComponent={<CardValuesHeader setSort={setSort} sort={sort} />} />
          </div>
          <div style={{ display: "flex", opacity: isLoading ? "0.4" : "1" }}>
            <div style={{ maxWidth: "648px", width: "100%" }}>
              {sort !== "Product Category" && (!isTablet || !isWide) ? <ListHeaderProvider isMobile={!isWide || isTablet}>{sort}</ListHeaderProvider> : null}
              {isTablet && isWide ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "337.5px", marginRight: "50px" }}>
                    <div className={styles.cardValuesHeaderTablet}>
                      <div style={{ marginLeft: "19px", marginRight: "19px", display: "flex" }}>
                        <CardValuesHeader setSort={setSort} sort={sort} />
                      </div>
                    </div>
                    {sort !== "Product Category" ? <ListHeaderProvider isMobile>{sort}</ListHeaderProvider> : null}
                    {productList}
                    <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} counts={counts} scrollRef={paginationRef} />
                  </div>
                  <div style={{ marginTop: "70px" }}>
                    <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                    <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
                  </div>
                </div>
              ) : (
                <div>
                  {productList}
                  <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} counts={counts} scrollRef={paginationRef} />
                </div>
              )}
            </div>
            {!isTablet ? (
              <div style={{ marginTop: "10px", marginLeft: "43px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
              </div>
            ) : null}
          </div>
          <div style={{ maxWidth: "648px" }}>
            {qa ? (
              <div style={{ marginTop: "60px" }}>
                {/* {compileSchemaOrgQa(qa.qa.qa)} */}
                {/* <div className={styles.listHeader}>
                    <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{"FAQs"}</div>
                  </div> */}
                <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
                {/* <div>
                  <Markdown md={qa.qa.qa} isArticle={true} />
                </div> */}
                <Faq faqContent={qa.qa.qa} />
              </div>
            ) : null}
            {"big4" in categoryJson ? <ProviderArticle provider={category} rewards={"Qantas Frequent Flyer"} /> : null}
            <CreditCardDisclaimer isDisclaimerOpen isProvider />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProviderTemplate
